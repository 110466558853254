<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
      <!-- Start Page Content -->
      <div id="report">
        <div v-for="(item, index) in items" class="break">
            <div class="row" style="text-align:center;">
                <div class="col-12" style="border-style:solid;border-width:1px 0px 1px 0px;padding:30px 30px 30px 30px;">
                    <p class="titlereport1">
                        <b class="titlereport1">CAJA Nº: </b>
                        <b class="titlereport2" style="border-width:2px 2px 2px 2px;border-style:double;border-radius:10px;padding:5px 5px 5px 5px;">
                            {{ numCaja(item.caj_cdgo) }}
                        </b>
                    </p>
                </div>
            </div>
            <div class="row" style="text-align:center;">
                <div class="col-6" style="border-style:solid;border-width:0px 1px 1px 0px;padding:20px 20px 20px 20px;">
                    <p class="subtitle">
                        Sala: {{ sal_nmbre }}
                    </p>
                </div>
                <div class="col-6" style="border-style:solid;border-width:0px 0px 1px 0px;padding:20px 20px 20px 20px;">
                    <p class="subtitle">
                        Tipo de archivo: {{ tpa_nmbre }}
                    </p>
                </div>
            </div>
            <div class="row" style="text-align:center">
                <div class="col-12" style="border-style:solid;border-width:0px 0px 1px 0px;padding:20px 20px 20px 20px;">
                    <p class="subtitle">
                        DEPENDENCIA: {{ item.dep_nmbre }}
                    </p>
                </div>
            </div>
            <div class="row" style="text-align:center">
                <div class="col-12" style="border-style:solid;border-width:0px 0px 1px 0px;padding:20px 20px 20px 20px;">
                    <p class="subtitle">
                        SERIE: {{ item.ser_nmbre }}
                    </p>
                </div>
            </div>
            <!--
            <div class="row" style="text-align:center">
                <div class="col-12" style="border-style:solid;border-width:0px 0px 1px 0px;padding:20px 20px 20px 20px;">
                    <p class="subtitle">
                        <b class="subtitle">No. Carpetas: </b>
                        <b class="subtitle" style="border-width:2px 2px 2px 2px;border-style:double;border-radius:10px;padding:3px 3px 3px 3px;">
                            {{ item.carpetas }}
                        </b>
                    </p>
                </div>
            </div>
            -->
            <div class="row" style="text-align:center">
                <div class="col-6" style="border-style:solid;border-width:0px 1px 1px 0px;padding:20px 20px 20px 20px;">
                    <p class="subtitle">
                        Fecha Inicial: {{ item.fechaini | formatDateUtc }}                                        
                    </p>
                </div>
                <div class="col-6" style="border-style:solid;border-width:0px 0px 1px 0px;padding:20px 20px 20px 20px;">
                    <p class="subtitle">
                        Fecha Final: {{ item.fechafin | formatDateUtc }}
                    </p>
                </div>
            </div>
        </div>
      </div>
        <br>
        <div v-if="showProgress" style="text-align:center">
            <v-progress-circular
            :size="50"
            :width="5"
            color="blue"
            indeterminate
            ></v-progress-circular>
        </div>
        <br>
        <div class="row justify-content-around" align="center">
        <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
        <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
        </div>
      <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDateUtc } from './../../plugins/filters';
import print from 'print-js';
import XLSX from 'xlsx';

export default {
  components: { 
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      dependencias: [],
      series: [],
      debug: null,
      fecha: null,
      tpa_nmbre: '',
      sal_nmbre: '',
      showProgress: false
    }
  },
  filters: {
    formatDateUtc
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    this.fetchDependencia();
    this.fetchSerie();
    this.debug = this.$route.params;
    this.tpa_nmbre = this.$route.params.tpa_nmbre;
    this.sal_nmbre = this.$route.params.sal_nmbre;
    this.searchItems(this.$route.params.query);
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchDependencia()
    {
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.dependencias.length; j++){
            if (this.items[i].dep_id == this.dependencias[j]._id){
              this.items[i].dep_nmbre = this.dependencias[j].dep_nmbre;
              j = this.dependencias.length;
            }
          }
        }
        /*
        let m = response.data.index;
        this.files[m]['uploaded'] = '0';
        this.$set(this.files, m, this.files[m]);
        */
      });
    },
    fetchSerie()
    {
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.series = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.series.length; j++){
            if (this.items[i].ser_id == this.series[j]._id){
              this.items[i].ser_nmbre = this.series[j].ser_nmbre;
              j = this.series.length;
            }
          }
        }

      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando datos';
        let uri = '/docs/report/etiqueta_caja';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;

          for (let i = 0; i < list.length; i++){
            for (let j = 0; j < this.dependencias.length; j++){
              if (list[i].dep_id == this.dependencias[j]._id){
                list[i].dep_nmbre = this.dependencias[j].dep_nmbre;
                j = this.dependencias.length;
              }
            }

            for (let j = 0; j < this.series.length; j++){
              if (list[i].ser_id == this.series[j]._id){
                list[i].ser_nmbre = this.series[j].ser_nmbre;
                j = this.series.length;
              }
            }

          }

          this.items = list;

          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/caja.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteEtiquetaCaja';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.est_cdgo = this.items[i].est_cdgo;
        item.caj_cdgo = this.items[i].caj_cdgo;
        item.lgj_nmro = this.items[i].lgj_nmro;
        item.documento = this.items[i].documento;
        item.dep_nmbre = this.items[i].dep_nmbre;
        item.ser_nmbre = this.items[i].ser_nmbre;
        item.dcm_fchaini = moment.utc(this.items[i].dcm_fchaini).format('DD/MMM/YYYY');
        if (this.items[i].dcm_fchafin != null){
          item.dcm_fchafin = moment.utc(this.items[i].dcm_fchafin).format('DD/MMM/YYYY');
        } else {
          item.dcm_fchafin = "";
        }
        item.dcm_asunto = this.items[i].dcm_asunto;

        report.push(item);
      }

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de inventario de documentos"],
      	["Tipo de archivo: " + this.tpa_nmbre],
        ["Sala: " + this.sal_nmbre],
        [],
        ["Estante", "Caja", "Inventario", "Documento", "Dependencia", "Serie", "Fecha inicio", "Fecha fin", "Nombre del expediente"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A9" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    },
    numCaja(num){
        var size = 4;
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

  } // END METHODS
}
</script>

<style>
  @import '../../../public/css/caja.css';
</style>
